import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.8.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ConnectButton"] */ "/app/src/components/common/ConnectButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DarkModeSwitch"] */ "/app/src/components/common/DarkModeSwitch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitch"] */ "/app/src/components/common/LanguageSwitch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/app/src/components/common/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/HomeTabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackToTopFAB"] */ "/app/src/components/site/BackToTopFAB.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FABContainer"] */ "/app/src/components/ui/FAB.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip"] */ "/app/src/components/ui/Tooltip.tsx");
